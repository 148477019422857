import React from 'react'
import { Link } from 'gatsby'
import { ModalBody, Form, FormGroup, Label, Input, Col } from 'reactstrap'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { KOTI_API_ROOT } from '../Constants'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet';
import { kotiTitleGenerator } from '../utils';

class IHomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false,
      modal: false,
      email: '',
      PDATE: moment()
        .add(5, 'days')
        .hours(15)
        .minutes(0),
      FNAME: '',
      LNAME: '',
      PHONE: '',
      errorFields: [],
      message: '',
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      modal: true,
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  handleDateChange = date => {
    this.setState({
      ...this.state,
      PDATE: date,
    })
  }

  handleInputChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    })
  }

  resetFormErrors() {
    this.setState({
      ...this.state,
      errorFields: [],
    })
  }

  formSubmit = e => {
    e.preventDefault()
    this.resetFormErrors()
    const self = this
    console.log(this.state)
    fetch(`${KOTI_API_ROOT}/api/v1/subscribe`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...this.state,
        PDATE: this.state.PDATE.toISOString(true),
      }),
    })
      .then(res => {
        console.log(res)
        if (!res.ok) {
          res.json().then(function(data) {
            const errorFields = []
            for (const item of data) {
              errorFields.push(item.param)
            }
            self.setState({
              ...self.state,
              errorFields,
            })
          })
          return
        }

        // success
        res.json().then(function(data) {
          self.setState({
            ...self.state,
            message: data.message,
          })
        })
      })
      .catch(error => console.log(error))
  }

  isInvalid = name => {
    return this.state.errorFields.includes(name)
  }

  render() {
    const modalBody = (
      <ModalBody>
        <section className="imageblock feature-large bg--white border--round">
          <div
            className="modal-close modal-close-cross"
            onClick={this.toggle}
          />
          <div className="imageblock__content col-lg-5 col-md-5 pos-left">
            <div
              className="background-image-holder"
              style={{
                backgroundImage: `url('${require('../assets/img/cowork-11.jpg')}')`,
                opacity: 1,
              }}
            />
          </div>
          <div className="container" style={{ paddingTop: 20 }}>
            <div className="row justify-content-end">
              <div className="col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-md-11 col-lg-10">
                    <h1>koti Premium for iHome</h1>
                    <p className="lead">
                      Let koti brings you the smart home experience.
                    </p>
                    <hr className="short" />
                    {this.state.message ? (
                      <>
                        <h3>{this.state.message}</h3>
                        <Link
                          to="/"
                          className="btn btn--primary"
                          style={{ color: 'white' }}
                        >
                          Click here to learn more about koti
                        </Link>
                      </>
                    ) : (
                      <Form className="row" onSubmit={this.formSubmit}>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="FNAME">First Name</Label>
                            <Input
                              type="text"
                              name="FNAME"
                              id="FNAME"
                              placeholder="Jon"
                              value={this.state.FNAME}
                              onChange={this.handleInputChange}
                              invalid={this.isInvalid('FNAME')}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="LNAME">Last Name</Label>
                            <Input
                              type="text"
                              name="LNAME"
                              id="LNAME"
                              placeholder="Snow"
                              value={this.state.LNAME}
                              onChange={this.handleInputChange}
                              invalid={this.isInvalid('LNAME')}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="PHONE">Mobile Number</Label>
                            <Input
                              type="tel"
                              name="PHONE"
                              id="PHONE"
                              placeholder="91234567"
                              value={this.state.PHONE}
                              onChange={this.handleInputChange}
                              invalid={this.isInvalid('PHONE')}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="jon@koti.ai"
                              value={this.state.email}
                              onChange={this.handleInputChange}
                              invalid={this.isInvalid('email')}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="PDATE">
                              Make an apointment for setup
                            </Label>
                            <DatePicker
                              customInput={<ExampleCustomInput />}
                              withPortal
                              selected={this.state.PDATE}
                              onChange={this.handleDateChange}
                              popperPlacement="top-end"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: '5px, 10px',
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                  boundariesElement: 'viewport',
                                },
                              }}
                              showTimeSelect
                              minDate={moment().add(5, 'days')}
                              minTime={moment()
                                .hours(10)
                                .minutes(0)}
                              maxTime={moment()
                                .hours(22)
                                .minutes(0)}
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="LLL"
                              timeCaption="time"
                            />
                          </FormGroup>
                        </Col>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn--primary type--uppercase"
                          >
                            Submit
                          </button>
                        </div>
                        <div className="col-12">
                          <span className="type--fine-print">
                            By submitting, you agree to the{' '}
                            <a href="/terms" target="_blank">
                              Terms of Service
                            </a>
                          </span>
                        </div>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ModalBody>
    )

    // return (
    //   <>
    //     <Modal
    //       isOpen={this.state.modal}
    //       toggle={this.toggle}
    //       modalClassName="modal-fullscreen"
    //       className={`modal-container ${
    //         this.state.modal ? 'modal-active' : ''
    //       }`}
    //       backdrop="static"
    //     >
    //       {modalBody}
    //     </Modal>
    //     <LandingPage />
    //   </>
    // )
    return (
      <Layout hideFooter>
        <Helmet title={kotiTitleGenerator('iHome')} />
        {modalBody}
      </Layout>
    )
  }
}

class ExampleCustomInput extends React.Component {
  render() {
    return (
      <a className="btn btn--lg" onClick={this.props.onClick}>
        {this.props.value}
      </a>
    )
  }
}
export default IHomePage
